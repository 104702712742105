import React from 'react';
import styled from 'styled-components';
import theme from '../../style/theme';

const StyledLine = styled.div`
  width: ${({ width })=> width ? width : '100px'};
  height: 1px;
  background: ${({ color })=>  color ? color : theme.color.green};
  margin: ${({ margin })=> margin ? margin : '25px auto'} ;
`;

const Line = ({ color, width, margin })=> <StyledLine color={color} width={width} margin={margin} />;

export default Line;
