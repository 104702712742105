import React from 'react';
import styled from 'styled-components';

const StyledCol33 = styled.div`
  width: calc(100%/3);
  padding: 0 16px;

  @media (max-width: ${({ theme })=> theme.breakpoints.width.tablet}) {
    width: 100%;
    padding: 0;
  }
`;

const Col33 = ({ children, className })=> (
  <StyledCol33 className={className}>{children}</StyledCol33>
);

export default Col33;
