
import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from 'style/theme.js';
import Header from './header';
import Footer from './footer';
import { GlobalStyle } from 'style/GlobalStyle';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faBars, faNewspaper, faEnvelope, faPhone, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import 'utils/i18n';

library.add(fab, faBars, faNewspaper, faEnvelope, faPhone, faMapMarkerAlt);

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Main = styled.main`
  flex: 1 0 auto;
`;

const Layout = ({ children })=> {
  return (<ThemeProvider theme={theme}>
    <GlobalStyle />
    <Wrapper>
      <Header />
      <Main>
        {children}
      </Main>
      <Footer />
    </Wrapper>
  </ThemeProvider>
  );
};
export default Layout;
