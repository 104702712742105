import React, { memo } from 'react';
import styled from 'styled-components';

const Button = styled.span`
  display: inline-block;
  border: 2px solid ${({ theme })=> theme.color.green};
  padding: 5px 30px;
  color: ${({ darkMode, theme })=> darkMode ? theme.color.green : 'white'};
  font-weight: 700;
  text-transform: uppercase;
  user-select: none;

  :hover {
    cursor: pointer;
  }
`;

const StyledButton = memo(({ children, onClick, darkMode })=> (
  <Button onClick={onClick} darkMode={darkMode}>
    {children}
  </Button>
));

export default StyledButton;
