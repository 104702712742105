import React from 'react';
import { LocalizedLink } from 'components/misc';
import styled from 'styled-components';
import theme from '../style/theme';
import { withTranslation } from 'react-i18next';

const Nav = styled.nav`
  @media (max-width: ${(props)=> props.theme.breakpoints.width.tablet}) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${(props)=> props.theme.color.white};
    transform: translateX(100%);
    transition: .5s ease-in-out;
    overflow-y: auto;
    z-index: 12;

    ${({ isOpen })=> isOpen && 'transform: translateX(0);'}
  }
`;

const StyledNavLink = styled(LocalizedLink)`
  font-size: 1.285rem;
  font-weight: 300;
  display: inline-block;
  letter-spacing: 0;
  border: 2px solid transparent;
  white-space: nowrap;
  margin-right: 50px;
  padding: 2px 10px;
  transition: .5s;
  &:last-of-type {
    margin-right: 0;
  }
  &:hover {
    color: #929292;
  }

  @media (max-width: ${(props)=> props.theme.breakpoints.width.tablet}) {
    display: block;
    font-size: 1.25rem;
    margin-left: 10px;
  }
`;

const CloseButton = styled.div`
  display: none;
  user-select: none;
  cursor: pointer;
  font-size: 1.5rem;

  @media (max-width: ${(props)=> props.theme.breakpoints.width.tablet}) {
    display: block;
    width: 25px;
    height: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: 15px;
  }
`;

const activeStyle = {
  background: theme.color.green,
  color: theme.color.bodyGray
};

const isActive = ({ isCurrent, location })=> {
  return isCurrent || location.pathname.startsWith('/news') ? { style: activeStyle } : {};
};

const ExtendedLink = (props)=> (
  <StyledNavLink getProps={isActive} {...props} />
);

const Navigation = ({ t, isOpen, onClick })=> {
  return (
    <Nav isOpen={isOpen}>
      <CloseButton onClick={onClick}>&#x2715;</CloseButton>
      <ExtendedLink onClick={onClick} to="/">Aktualności</ExtendedLink>
      <StyledNavLink partiallyActive activeStyle={activeStyle} onClick={onClick} to={'/key-activities'}>Kluczowe działalności</StyledNavLink>
      <StyledNavLink partiallyActive activeStyle={activeStyle} onClick={onClick} to={'/investors'}>Dla akcjonariuszy</StyledNavLink>
      <StyledNavLink partiallyActive activeStyle={activeStyle} onClick={onClick} to={'/contact'}>Kontakt</StyledNavLink>
    </Nav>
  );
};

export default withTranslation()(Navigation);
