import React from 'react';
import styled from 'styled-components';
import { media } from 'utils';

const StyledMobileHeading = styled.h2`
  display: none;
  font-size: 1.285rem;
  margin-top: 8px;
  margin-bottom: 20px;
  color: ${({ theme })=> theme.color.green};

  ${media.tablet`
    display: block;
  `}
`;

const MobileHeading = ({ heading })=> <StyledMobileHeading>{heading}</StyledMobileHeading>;

export default MobileHeading;
