import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { media } from 'utils';

const StyledGoBackButton = styled.div`
  display: none;
  padding: 8px 0;
  font-weight: 300;

  ${media.tablet`
    display: block;
  `}
`;

const StyledLink = styled(Link)`
  display: block;
`;

const GoBackButton = ({ path, name, className })=> {
  return <StyledGoBackButton className={className}>
    <StyledLink to={`/${path}`}>
      &#x2190; {name}
    </StyledLink>
  </StyledGoBackButton>;
};

export default GoBackButton;
