import React, { memo, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Container, Col25, Col33 } from 'components/misc';
import LogoWhite from '../assets/images/logo-white.svg';
import UpArrow from '../assets/images/up-arrow.svg';

const Footer = styled.footer`
  flex-shrink: 0;
  background-color: ${({ theme })=> theme.color.footerBlack};
  padding: 50px 20px;
  color: ${({ theme })=> theme.color.white};
`;

const ScrollToTop = styled.div`
  position: absolute;
  top: -50px;
  right: 0;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 54px;
  height: 54px;
  background: ${({ theme })=> theme.color.green};
  color: ${({ theme })=> theme.color.white};

  svg {
    fill: #fff;
  }

  :hover {
    cursor: pointer;
  }
`;

const FlexContainer = styled(Container)`
  position: relative;
  display: flex;
  justify-content: space-between;

  @media (max-width: ${({ theme })=> theme.breakpoints.width.tablet}) {
    flex-direction: column;
  }
`;

const StyledH3 = styled.h3`
  color: ${({ theme })=> theme.color.footerTitle};
  font-size: 1.715rem;
  font-weight: 400;
  margin-bottom: 25px;

  @media (max-width: ${({ theme })=> theme.breakpoints.width.tablet}) {
    margin-top: 25px;
  }
`;

const AddressTypeWrapper = styled.div`
  margin-bottom: 25px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const StyledP = styled.p`
  color: ${({ theme })=> theme.color.white};
  font-weight: 200;
  margin-bottom: 25px;
`;

const StyledList = styled.ul`
  font-size: 1.285rem;
  font-weight: 300;
  margin-top: 25px;
`;

const Regulations = styled(Col33)`
  @media (max-width: ${({ theme })=> theme.breakpoints.width.tablet}) {
    margin-top: 100px;
  }
`;

const SocialMedia = styled.div`
  margin-top: 80px;
  a {
    display: block;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const StyledFooter = memo(({ t })=> {
  const [yPosition, setYPosition] = useState(0);

  const handleScroll = ()=> {
    setYPosition(window.scrollY);
  };

  const handleScrollTop = ()=> {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(()=> {
    window.addEventListener('scroll', handleScroll);
    return ()=> {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return <Footer>
    <FlexContainer>
      {yPosition ? <ScrollToTop onClick={handleScrollTop}><UpArrow height={30} /></ScrollToTop> : undefined}
      <Col33>
        <StyledH3>O grupie</StyledH3>
        <StyledList>
          <li><Link to="/">Aktualności</Link></li>
          <li><Link to="key-activities">Kluczowe działalności</Link></li>
          <li><Link to="investors">Dla akcjonariuszy</Link></li>
          <li><Link to="contact">Kontakt</Link></li>
        </StyledList>
      </Col33>
      <Col33>
        <AddressTypeWrapper>
          <StyledH3>Kontakt</StyledH3>
          <StyledP>Grupa Ekoenergetyka SA<br /> ul. Postępu 14 <br /> 02-676 Warszawa</StyledP>
          <StyledP>Kontakt ogólny: <br /> kontakt@grupaekoenergetyka.pl</StyledP>
        </AddressTypeWrapper>
      </Col33>
      <Regulations>
        <LogoWhite height={40} />
        <StyledList>
          <li><Link to="privacy-policy">Polityka prywatności</Link></li>
          <li>Polityka cookies</li>
          <li>Regulamin</li>
        </StyledList>
        <SocialMedia>
          <a href="https://www.linkedin.com/company/ekoen" target="_blank" rel="noopener noreferrer">Linked in</a>
          <a href=" https://www.facebook.com/EvityElectricTaxi" target="_blank" rel="noopener noreferrer">Facebook</a>
          {/* <a href="#">Twitter</a> */}
        </SocialMedia>
      </Regulations>
    </FlexContainer>
  </Footer>;
});

export default withTranslation()(StyledFooter);
