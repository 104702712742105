import React from 'react';
import styled from 'styled-components';

const StyledCol25 = styled.div`
  width: 25%;
  padding: 0 16px;

  @media (max-width: ${({ theme })=> theme.breakpoints.width.tablet}) {
    width: 100%;
  }
`;

const Col25 = ({ children, className })=> (
  <StyledCol25 className={className}>{children}</StyledCol25>
);

export default Col25;
