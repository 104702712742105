import React from 'react';
import styled from 'styled-components';

const StyledFlexContainer = styled.div`
  display: flex;

  @media (max-width: ${({ theme })=> theme.breakpoints.width.tablet}) {
    flex-direction: column;
  }
`;

const FlexContainer = ({ children, className })=> {
  return (
    <StyledFlexContainer className={className}>
      {children}
    </StyledFlexContainer>
  );
};

export default FlexContainer;
