import React from 'react';
import styled from 'styled-components';

const StyledSection = styled.section`
  padding-top: ${({ topSection })=> topSection ? '235px' : '65px'};
  padding-right: 35px;
  padding-bottom: 65px;
  padding-left: 35px;

  @media (max-width: ${({ theme })=> theme.breakpoints.width.tablet}){
    padding-top: ${({ topSection })=> topSection ? '120px' : '36px'};
  }
`;

const Section = ({ children, className, topSection })=> {
  return (
    <StyledSection
      className={className}
      topSection={topSection}>
      {children}
    </StyledSection>
  );
};

export default Section;
