import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { media } from 'utils';

const StyledAside = styled.aside`
  width : 25%;
  padding-right: 50px;

  li {
    margin: 15px 0;
  }

  ${media.tablet`
    width: 100%;
    padding: 0;
  `}
`;

const StyledH2 = styled.h2`
  font-size: 2.25rem;
  color: ${({ theme })=> theme.color.green};
  margin-bottom: 34px;
  font-weight: 700;

  ${media.tablet`
    display: none;
  `}
`;

const StyledP = styled.p`
  color: ${({ theme })=> theme.color.green};
  margin: 15px 0;
  font-weight: normal;

  ${media.tablet`
    display: none;
  `}
`;

const ChildrenWrapper = styled.div`
  opacity: .7;
`;

const Aside = ({ children, title, className, withTitleAndShortcut, titleAsLink, linkRef, as })=> {
  let header = undefined;

  if (withTitleAndShortcut) {
    header = (
      <>
        {titleAsLink ? <Link to={linkRef}><StyledH2>{title}</StyledH2></Link> : <StyledH2>{title}</StyledH2>}
        <StyledP>Na skróty:</StyledP>
      </>
    );
  } else if (title) {
    header = <StyledH2>{title}</StyledH2>;
  }

  return (
    <StyledAside className={className} as={as}>
      {header}
      <ChildrenWrapper>
        {children}
      </ChildrenWrapper>
    </StyledAside>
  );
};

export default Aside;
