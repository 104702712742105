import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from '../locales/en/translation.json';
import translationPL from '../locales/pl-PL/translation.json';

const resources = {
  'en-GB':
  {
    translation: translationEN
  },
  'en-US':
  {
    translation: translationEN
  },
  en: {
    translation: translationEN
  },
  'pl-PL': {
    translation: translationPL
  },
  pl: {
    translation: translationPL
  }
};

const options = {
  // order and from where user language should be detected
  order: ['navigator'],

  // keys or params to lookup language from
  // lookupLocalStorage: 'i18nextLng',
  // lookupFromPathIndex: 0,
  // lookupFromSubdomainIndex: 0,

  // cache user language on
  // caches: ['localStorage', 'cookie'],
  // excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    react: {
      useSuspense: false
    },
    fallbackLng: 'pl',
    debug: false,
    resources,
    detection: options,
    keySeparator: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });

export default i18n;
