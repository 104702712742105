import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { LocalizedLink, Container } from 'components/misc';
import LogoBlack from '../assets/images/logo-black.svg';
import Navigation from './navigation';

const Header = styled.header`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: ${({ isSticked })=> isSticked ? '25px 25px' : '50px 35px'};
  background: ${({ isSticked })=> isSticked ? 'white' : 'transparent'};
  color: ${({ theme })=> theme.color.gray};
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  transition: .5s;
`;

const LogoSvg = styled(LogoBlack)`
  height: 40px;

  @media (max-width: ${({ theme })=> theme.breakpoints.width.tablet}) {
    height: 26px;
  }
`;

const HamburgerIcon = styled.span`
  display: none;
  @media (max-width: ${({ theme })=> theme.breakpoints.width.tablet}) {
    display: block;
    font-size: 1.5rem;
  }
`;

const HeaderWrapper = styled.div`
  width: ${({ theme })=> theme.settings.sectionWidth};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledHeader = ()=> {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isWindowScroll, setIsWindowScroll] = useState(false);

  const handleScroll = (e)=> {
    if (window.scrollY) {
      setIsWindowScroll(true);
    } else {
      setIsWindowScroll(false);
    }
  };

  useEffect(()=> {
    document.addEventListener('scroll', handleScroll, { passive: true });
  }, []);

  const handleOpenNav = ()=> {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <Header isSticked={isWindowScroll}>
      <HeaderWrapper>
        <LocalizedLink to="/">
          <LogoSvg />
        </LocalizedLink>
        <Navigation onClick={handleOpenNav} isOpen={isNavOpen} />
        <HamburgerIcon onClick={handleOpenNav}><FontAwesomeIcon icon="bars" /></HamburgerIcon>
      </HeaderWrapper>
    </Header>
  );
};
export default StyledHeader;
