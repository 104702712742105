import React, { memo } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  max-width: ${({ theme })=> theme.settings.sectionWidth};
  margin: 0 auto;
`;

const StyledContainer = memo(({ children, className })=> (
  <Container className={className}>
    {children}
  </Container>
));

export default StyledContainer;
