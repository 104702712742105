import { createGlobalStyle } from 'styled-components';
import theme from './theme';

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body, html {
    height: 100%;
  }

  html {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
  }

  body {
    background: ${theme.color.bodyGray};
    overflow-x: hidden;
  }

  a {
    transition: .5s;
    cursor: pointer;
    text-decoration: none;
    color: inherit;
  }

  h2 {
    font-weight: 700;
    font-size: 4rem;
    color: ${theme.color.gray};
  }

  h3 {
    font-size: 3rem;
    font-weight: 700;
    color: ${theme.color.gray};
  }

  h4 {
    font-size: 2rem;
    font-weight: 700;
    color: ${theme.color.gray};
  }

  h5 {
    font-weight: 700;
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: ${theme.color.gray};
  }

  img {
    max-width: 100%;
    object-fit: cover;
  }

  p {
    color: ${theme.color.black};
    font-weight: 300;
    line-height: 1.5em;
    z-index: 3;
  }

  li {
    list-style: none;
  }
`;
