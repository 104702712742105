// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-layouts-news-layout-jsx": () => import("./../src/layouts/news-layout.jsx" /* webpackChunkName: "component---src-layouts-news-layout-jsx" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-index-js": () => import("./../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investors-communication-jsx": () => import("./../src/pages/investors/communication.jsx" /* webpackChunkName: "component---src-pages-investors-communication-jsx" */),
  "component---src-pages-investors-corporate-governance-board-of-directors-jsx": () => import("./../src/pages/investors/corporate-governance/board-of-directors.jsx" /* webpackChunkName: "component---src-pages-investors-corporate-governance-board-of-directors-jsx" */),
  "component---src-pages-investors-corporate-governance-index-js": () => import("./../src/pages/investors/corporate-governance/index.js" /* webpackChunkName: "component---src-pages-investors-corporate-governance-index-js" */),
  "component---src-pages-investors-corporate-governance-shareholders-jsx": () => import("./../src/pages/investors/corporate-governance/shareholders.jsx" /* webpackChunkName: "component---src-pages-investors-corporate-governance-shareholders-jsx" */),
  "component---src-pages-investors-financial-documents-index-js": () => import("./../src/pages/investors/financial-documents/index.js" /* webpackChunkName: "component---src-pages-investors-financial-documents-index-js" */),
  "component---src-pages-investors-index-js": () => import("./../src/pages/investors/index.js" /* webpackChunkName: "component---src-pages-investors-index-js" */),
  "component---src-pages-key-activities-js": () => import("./../src/pages/key-activities.js" /* webpackChunkName: "component---src-pages-key-activities-js" */),
  "component---src-pages-media-awards-jsx": () => import("./../src/pages/media/awards.jsx" /* webpackChunkName: "component---src-pages-media-awards-jsx" */),
  "component---src-pages-media-book-of-mark-jsx": () => import("./../src/pages/media/book-of-mark.jsx" /* webpackChunkName: "component---src-pages-media-book-of-mark-jsx" */),
  "component---src-pages-media-foto-video-jsx": () => import("./../src/pages/media/foto-video.jsx" /* webpackChunkName: "component---src-pages-media-foto-video-jsx" */),
  "component---src-pages-media-index-js": () => import("./../src/pages/media/index.js" /* webpackChunkName: "component---src-pages-media-index-js" */),
  "component---src-pages-media-presentations-jsx": () => import("./../src/pages/media/presentations.jsx" /* webpackChunkName: "component---src-pages-media-presentations-jsx" */),
  "component---src-pages-media-press-info-jsx": () => import("./../src/pages/media/press-info.jsx" /* webpackChunkName: "component---src-pages-media-press-info-jsx" */),
  "component---src-pages-news-archive-js": () => import("./../src/pages/news/archive.js" /* webpackChunkName: "component---src-pages-news-archive-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */)
}

