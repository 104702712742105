import React from 'react';
import styled from 'styled-components';

const StyledArrowToBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DownloadArrow = styled.p`
  color: ${({ theme })=> theme.color.green};
  font-size: 1.285rem;
  font-weight: 500;
  cursor: pointer;
`;

const Title = styled(DownloadArrow)`
  font-size: inherit;
`;

const ArrowToBottom = ({ className, title, onClick })=> {
  return (
    <StyledArrowToBottom className={className}>
      {title ? <Title>{title}</Title> : undefined}
      <DownloadArrow title={title} onClick={onClick}>&#10515;</DownloadArrow>
    </StyledArrowToBottom>
  );
};

export default ArrowToBottom;
