export default {
  settings: {
    sectionWidth: '1600px',
    sectionPadding: '75px 25px',
    heroMinHeight: '650px'
  },
  color: {
    bodyGray: ' #F5F5F5',
    black: '#000000',
    green: '#2e2c7e',
    gray: '#434343',
    footerBlack: '#2D2D2D',
    footerTitle: '#F8F8F8',
    white: '#FFFFFF',
  },
  breakpoints: {
    width: {
      mobile: '480px',
      tablet: '800px',
      desktop: '1024px',
      largeDesktop: '1920px'
    }
  }
};
