import React, { memo } from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';

const MapWrapper = styled.div`
  width: 100%;
`;

const StyledMap = memo(()=> (
  <MapWrapper>
    <iframe style={{ filter: 'grayscale(100%)' }} width="100%" height="400px" id="gmap_canvas"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1398.5337252673994!2d15.615257676279619!3d51.92596860505988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47060d222f483fab%3A0xefd5e172271fee8f!2sEKOEN!5e0!3m2!1spl!2spl!4v1582885234973!5m2!1spl!2spl"
      frameBorder="0"
      scrolling="no"
      marginHeight="0"
      marginWidth="0" />
  </MapWrapper>
));

export default withTranslation()(StyledMap);
